var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "flex flex-wrap justify-center" }, [
      _vm._v("Sell Rate"),
    ]),
    _c(
      "div",
      { staticClass: "flex flex-wrap justify-center" },
      [
        _c("vs-input", {
          staticClass: "w-full mt-1",
          attrs: {
            type: "number",
            size: "small",
            step: "0.0001",
            placeholder: "Markup",
          },
          model: {
            value: _vm.markup,
            callback: function ($$v) {
              _vm.markup = _vm._n($$v)
            },
            expression: "markup",
          },
        }),
        _c(
          "vs-button",
          {
            staticClass: "mt-2 w-full",
            attrs: { size: "small", color: "primary", type: "border" },
            on: { click: _vm.onApply },
          },
          [_vm._v("Apply")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }