var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "flex flex-wrap justify-center" }, [
      _vm._v("API Rate"),
    ]),
    _c(
      "div",
      { staticClass: "flex flex-wrap justify-center" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-2 w-full",
            attrs: { size: "small", color: "primary", type: "border" },
            on: { click: _vm.onUpdate },
          },
          [_vm._v("Retrieve Cur. Rates")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }